.CardImageRight {
  padding-top: 155px;
  background-color: #ffffff;
  text-align: left;
  font-family: "Quicksand", sans-serif;

}

.CardImageRight h2 {
  font-size: 40px;
  line-height: 48px;
  font-weight: 500;
  margin-bottom: 30px;
}

.CardImageRight p {
  font-size: 20px;
}

.CardImageRight a.mod-btn {
  background: linear-gradient(#ffffff, #ffffff) 50% 50%/calc(100% - 4px) calc(100% - 4px) no-repeat, linear-gradient(135deg, #7049ba 0%, #e95095 100%);
  border-radius: 0px;
  padding: 14px 40px;
  box-sizing: border-box;
  color: #3776ff;
  text-transform: uppercase;
  text-decoration: none;
}

.CardImageRight a.mod-btn .span-text {
  background: linear-gradient(120deg, #8F42EC 0%, #FF4A9E 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-transform: uppercase;
  font-size: 0.9em !important;
}

.CardImageRight .mod-right {
  transition: opacity .4s ease .1s;
  background-size: cover;
  background-position: center center;
}


.CardImageRight .banner-buttons a.btn-propostal {
  color: #ffffff;
  background-color: #5e6777;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  padding: 16px 12px;
  display: flex;
  transition: background-color .2s;
  font-size: 20px;
  font-weight: 600;
  line-height: 32px;
  width: 190px;
  height: 56px;
  margin-right: 16px;
  text-decoration: none;
  transition: background-color .2s;
}


.CardImageRight .banner-buttons a.btn-propostal:hover {
  background-color: #363b44;
}


.CardImageRight .banner-buttons a.btn-play-video {
  color: #0f1825;
  background-color: #ffffff;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  padding: 16px 12px;
  display: flex;
  transition: background-color .2s;
  font-size: 20px;
  font-weight: 600;
  line-height: 32px;
  width: 146px;
  height: 56px;
}


@media (max-width: 768px) {
  .CardImageRight .image {
    width: 100%;
    min-height: 400px;
    margin-bottom: 40px;
    border-radius: 4px;
  }
}