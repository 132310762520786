.GalleryButtons_Texted {
  font-family: "Quicksand", sans-serif;
  padding-top: 60px;
  padding-bottom: 60px;
  background: #f9f9f6;
}

.GalleryButtons_Texted .mod-item-cover {
  margin-bottom: 50px;
}

.GalleryButtons_Texted .mod-item {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: end;
  position: relative;
  font-size: 20px;
}

.GalleryButtons_Texted .mod-item h3 {
  font-size: 20px;
  font-family: "Quicksand", sans-serif;
  text-align: left;
}

.GalleryButtons_Texted .mod-item-image {
  position: relative;
}

.GalleryButtons_Texted .mod-item-image::after {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
  background: rgba(0, 0, 0, .4);
}

.GalleryButtons_Texted .mod-item-image img {
  width: 100%;
  height: 250px;
}

.GalleryButtons_Texted .mod-btn {
  position: absolute;
  bottom: 0px;
  left: 0px;
  background: rgba(255, 255, 255, .8);
  width: 100%;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 12px;
  font-size: 20px;
}

.GalleryButtons_Texted a.section-btn {
  width: fit-content;
  padding: 10px 30px;
  color: #ffffff;
  text-align: center;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  background-color: #5e6777;
  margin: 0px auto;
}

.GalleryButtons_Texted a.section-btn:hover {
  background-color: #363b44;
}