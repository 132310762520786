.HeroLeft {
  padding-top: 120px;
  padding-bottom: 120px;
  padding-left: 40px;
  padding-right: 40px;
  background-color: #0f1825;
  color: #ffffff;
  background-image: url(./bg.svg);
  background-position: 100% -25%;
  background-repeat: no-repeat;
  background-size: auto;
  font-family: "Quicksand", sans-serif;
  ;
}

.HeroLeft .mod-content {
  max-width: 700px;
}

.HeroLeft h2 {
  font-size: 50px;
  line-height: 62px;
  font-weight: 400;
  color: #ffffff;
  margin-bottom: 24px;
}

.HeroLeft p {
  font-size: 20px;
  font-weight: 400;
  line-height: 34px;
  color: #ffffff;
  padding-right: 250px;
  margin-bottom: 30px;
}

.HeroLeft .mod-buttons {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.HeroLeft .mod-buttons a.btn-propostal {
  color: #0f1825;
  background-color: #7aff87;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  padding: 16px 12px;
  display: flex;
  transition: background-color .2s;
  font-family: "Quicksand", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 32px;
  width: 190px;
  height: 56px;
  margin-right: 16px;
}

.HeroLeft .mod-buttons a.btn-propostal img {
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;
  width: 19px;
  margin-right: 16px;
}

.HeroLeft .mod-buttons a.btn-play-video {
  color: #0f1825;
  background-color: #ffffff;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  padding: 16px 12px;
  display: flex;
  transition: background-color .2s;
  font-family: "Quicksand", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 32px;
  width: 146px;
  height: 56px;
}

@media (max-width:1200px) {
  .HeroLeft {
    background-position: 100% -50%;
  }
}

@media(max-width:992px) {
  .HeroLeft {
    background-position: 200% -50%;
  }
}

@media (max-width:768px) {
  .HeroLeft {
    padding-left: 15px;
    padding-right: 15px;
    background-image: none;
  }

  .HeroLeft h2 {
    font-size: 45px;
    line-height: 55px;
  }

  .HeroLeft p {
    padding-right: 0px;
  }

  .HeroLeft .modbuttons {
    flex-direction: column;
  }

  .HeroLeft .modbuttons a.btn-propostal {
    width: 100%;
    margin-right: 0px;
    margin-bottom: 10px;
  }

  .HeroLeft .modbuttons a.btn-play-video {
    width: 100%;
  }
}