/* NotFound.css */
.not-found-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
    background-color: #f0f0f0; /* Set your desired background color */
  }
  
  .not-found-title {
    font-size: 24px;
    color: #333; /* Set your desired title color */
  }
  
  .not-found-text {
    font-size: 16px;
    margin-top: 16px;
    color: #555; /* Set your desired text color */
  }
  
  .not-found-link {
    margin-top: 24px;
    font-size: 18px;
    text-decoration: none;
    padding: 10px 20px;
    background-color: #007bff; /* Set your desired button color */
    color: #fff; /* Set your desired button text color */
    border-radius: 5px;
    transition: background-color 0.3s ease-in-out;
  }
  
  .not-found-link:hover {
    background-color: #0056b3; /* Change the button color on hover */
  }
  