.B1_N1 .navbar {
    font-size: 16px;
    font-weight: 500;
    line-height: 32px;
    top: 0px;
    transition: padding-top 0.3s ease-in-out;
    background-color: #D3D3D3;
    z-index: 1111;
    transition: all 0.5s;
}

.B1_N1 .transparent {
    background-color: #d3d3d371 !important;
}

/* navigation css */
.B1_N1 nav.navbar {
    font-size: 16px;
    font-weight: 500;
    line-height: 32px;
    font-family: "Poppins";
    padding-left: 5vw;
    padding-right: 5vw;
    padding-top: 32px;
    padding-bottom: 32px;
    background-color: #F9F9F6;
    width: 100%;
    left: 0px;
    top: 0px;
    z-index: 111;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 300ms;
    opacity: 1;
    transform: translateY(0);
}

.B1_N1 nav.navbar.scrolled {
    opacity: 0;
    transform: translateY(-101%);
}

.B1_N1 nav.navbar .container-fluid {
    padding-left: 0px;
    padding-right: 0px;
}

.B1_N1 .navbar-brand {
    padding: 0px;
    margin: 0px;
}

.B1_N1 .navbar-brand img {
    height: 54px;
}

.B1_N1 .nav-link {
    color: #333b22;
    font-size: 14px;
    line-height: 24px;
    padding-top: 6px;
    padding-bottom: 6px;
    background-image: linear-gradient(#ff6482, #ff6482);
    background-position: bottom;
    background-size: 0 2px;
    background-repeat: no-repeat;
    text-decoration: none !important;
    transition-delay: 0s;
    transition-duration: .3s;
    transition-property: all;
}

.B1_N1 .nav-link:hover {
    color: #333b22;
    background-size: 100% 2px;
}

.B1_N1 .nav-link.active {
    color: #333b22;
}

.B1_N1 .nav-link:focus {
    color: #333b22;
}

.B1_N1 .langauage-flag {
    width: 40px;
    height: 40px;
    display: flex;
    align-content: center;
    justify-content: center;
    filter: grayscale(100%);
    background-size: cover;
    background-position: center;
    background-color: red;
    border-radius: 100%;
}

.B1_N1 .langauage-flag.flag {
    background-image: url(./images/flag.svg);
}

.B1_N1 .langauage-flag.flag1 {
    background-image: url(./images/flag1.svg);
}

.B1_N1 .langauage-flag:hover {
    filter: grayscale(0%);
}

.B1_N1 .navbar-toggler {
    border: 0px;
}

.B1_N1 .navbar-toggler:focus {
    box-shadow: none;
    outline: 0px;
    border: 0px;
}

.B1_N1 .navbar-toggler.collapsed .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 1%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.B1_N1 .navbar-toggler .navbar-toggler-icon {
    background-image: url(./images/close.png);
}