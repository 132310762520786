.BoxIcon {
  padding-top: 100px;
  padding-bottom: 100px;
  background-color: #c8e8e0;
  font-family: "Quicksand", sans-serif;
  padding-left: 40px;
  padding-right: 40px;
}

.BoxIcon h3.sub-title {
  font-size: 24px;
  line-height: 36px;
  font-weight: 400;
  color: #2e937a;
  text-align: center;
  margin-bottom: 24px;
}

.BoxIcon h2.title {
  font-size: 50px;
  line-height: 62px;
  font-weight: 400;
  color: #2e937a;
  margin-bottom: 24px;
  text-align: center;
  margin-bottom: 50px;
}

.BoxIcon .cards {
  display: flex;
  align-items: stretch;
  justify-content: center;
}

.BoxIcon .cards .card-item-cover {
  width: 20%;
  height: auto;
  padding: 0px 12px;
  margin-bottom: 24px;
}

.BoxIcon .cards .card-item {
  background-color: #ffffff;
  height: 100%;
  border-radius: 20px;
  text-align: center;
  padding: 20px 10px 30px;
}

.BoxIcon .cards .card-item img {
  width: 80%;
  display: block;
  margin: 0 auto;
}

.BoxIcon .cards .card-item h3 {
  font-size: 20px;
  line-height: 30px;
  margin: 20px auto 0px;
  color: #2e937a;
  font-family: "Quicksand", sans-serif;
  font-weight: 600;
}

.BoxIcon .cards .card-item p {
  margin: 10px 0px 15px;
  color: #2e937a;
  font-size: 16px;
  font-weight: 400;
}

.BoxIcon .cards .card-item a.btn-more {
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 2px;
  border: 2px solid #2e937a;
  margin: 25px auto 25px;
  padding: 6px 30px 7px 30px;
  color: #ffffff;
  background-color: #2e937a;
  border-radius: 100px;
}

@media(max-width:992px) {

  .BoxIcon .cards {
    flex-wrap: wrap;
    justify-content: center;
  }

  .BoxIcon .cards .card-item-cover {
    width: 33.33%;
    flex: 0 0 33.33%;
  }

}

@media (max-width:768px) {
  .BoxIcon {
    padding-left: 15px;
    padding-right: 15px;
  }

  .BoxIcon h2.title {
    font-size: 45px;
    line-height: 55px;
  }

  .BoxIcon .cards .card-item-cover {
    flex: 0 0 100%;
    padding: 0px 15px;
  }
}