.FormContactSmall {
  padding-top: 100px;
  padding-bottom: 100px;
  font-family: "Quicksand", sans-serif;
  ;
  padding-left: 40px;
  padding-right: 40px;
  background-color: #FFF1E0;
}

.FormContactSmall h2 {
  font-size: 50px;
  line-height: 62px;
  font-weight: 400;
  margin-bottom: 24px;
}

.FormContactSmall p {
  font-size: 18px;
  font-weight: 400;
  line-height: 1.5;
  margin-bottom: 30px;
}

.FormContactSmall ul {
  list-style-type: none;
  padding-left: 0px;
}

.FormContactSmall ul li {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3E%3Cpath fill='%23503EFF' d='M0 11l2-2 5 5L18 3l2 2L7 18z'/%3E%3C/svg%3E");
  background-size: 14px;
  padding-left: 20px !important;
  background-position: 0px 6px;
  background-repeat: no-repeat;
  font-size: 18px;
}

.FormContactSmall .form-container {
  margin-left: auto;
  max-width: 400px;
}

.FormContactSmall .form-container form {
  background-color: rgba(0, 0, 0, 0);
  border-radius: 6px;
  margin: 0;
  box-shadow: none;
  width: 100%;
  background-color: #fff;
  box-shadow: 4px 6px 30px rgba(0, 0, 0, .12);
  border-radius: 5px;
  padding: 12px 32px;
}

.FormContactSmall .form-container form .form-group {
  padding: 12px 0px;
}

.FormContactSmall .form-container form input.form-control {
  font-weight: 400;
  font-size: 16px;
  color: #393D3F;
  background-color: #f6f6f6;
  border-radius: 2px;
  border: 1px solid #FFFFFF;
  line-height: normal;
  padding: 13px 13px;
}

.FormContactSmall .form-container form input.form-control:focus {
  border: 1px solid #503EFF;
  box-shadow: none;
  outline: none;
}

.FormContactSmall .form-container .btn-submit {
  background-color: #503EFF;
  border: 2px solid #503EFF;
  border-radius: 8px;
  box-shadow: 0px 0px 0px;
  color: #ffffff;
  text-shadow: 0px 0px 0px #ffffff;
  letter-spacing: normal;
  padding: 12.5px 25px;
  font-size: 16px;
  font-weight: 500;
  width: 100%;
  line-height: normal;
}

.FormContactSmall .form-container .btn-submit:hover {
  background-color: #383690;
  border: 2px solid #383690;
}

@media (max-width:768px) {

  .FormContactSmall h2 {
    font-size: 45px;
    line-height: 55px;
  }

  .FormContactSmall {
    padding-left: 15px;
    padding-right: 15px;
  }

  .FormContactSmall .form-container form {
    padding: 12px 15px;
    margin-top: 30px;
  }
}