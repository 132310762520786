.B1_S3 {
    padding-top: 95px;
    padding-bottom: 95px;
    background-color: #ffffff;
}

.B1_S3 h2 {
    font-size: 48px;
    line-height: 48px;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    margin-bottom: 30px;
}

.B1_S3 p {
    font-size: 16px;
    line-height: 1.7;
    margin-bottom: 30px;
}

.B1_S3 .pricing-items {
    padding-top: 40px;
}

.B1_S3 .pricing-item {
    padding: 60px 40px;
    box-shadow: 0 3px 20px -2px rgba(20, 45, 100, 0.1);
    background: #fff;
    height: 100%;
    border-top: 4px solid #fff;
    border-radius: 5px;
}

.B1_S3 .pricing-item h3 {
    font-weight: 500;
    margin-bottom: 15px;
    font-size: 20px;
    color: #37517e;
}

.B1_S3 .pricing-item h4 {
    font-size: 48px;
    color: #37517e;
    font-weight: 400;
    margin-bottom: 25px;
}

.B1_S3 .pricing-item h4 sup {
    font-size: 28px;
}

.B1_S3 .pricing-item h4 span {
    color: #47b2e4;
    font-size: 18px;
    display: block;
}

.B1_S3 .pricing-item ul {
    padding: 20px 0;
    list-style: none;
    color: #999;
    text-align: left;
    line-height: 20px;
}

.B1_S3 .pricing-item ul li {
    padding: 10px 0 10px 30px;
    position: relative;
}

.B1_S3 .pricing-item ul i {
    color: #28a745;
    font-size: 20px;
    position: absolute;
    left: 0;
    top: 10px;
}

.B1_S3 .pricing-item ul .na {
    color: #ccc;
}

.B1_S3 .pricing-item ul .na i {
    color: #ccc;
}

.B1_S3 .pricing-item ul .na span {
    text-decoration: line-through;
}

.B1_S3 .pricing-item .buy-btn {
    display: inline-block;
    padding: 12px 35px;
    border-radius: 50px;
    color: #47b2e4;
    transition: none;
    font-size: 16px;
    font-weight: 500;
    transition: 0.3s;
    border: 1px solid #47b2e4;
    text-decoration: none;
}

.B1_S3 .pricing-item.featured {
    border-top-color: #47b2e4;
}

.B1_S3 .pricing-item.featured .buy-btn {
    background: #47b2e4;
    color: #fff;
}

.B1_S3 .pricing-item .buy-btn:hover {
    background: #47b2e4;
    color: #fff;
}

.B1_S3 .pricing-item.featured .buy-btn:hover {
    background: #23a3df;
}