.GalleryLinks {
  padding-top: 95px;
  padding-bottom: 95px;
  background-color: #F6F7FA;
}

.GalleryLinks h2 {
  font-size: 48px;
  line-height: 48px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  margin-bottom: 30px;
}

.GalleryLinks p {
  font-size: 16px;
  line-height: 1.7;
  margin-bottom: 30px;
}

.GalleryLinks .landing-links {
  margin-top: 30px;
}

.GalleryLinks .landing-link-cover {
  margin-bottom: 16px;
}

.GalleryLinks .landing-link {
  color: #0f1825;
  background-color: #fff;
  flex-direction: column;
  align-items: stretch;
  padding: 24px;
  text-decoration: none;
  transition: box-shadow .2s;
  display: flex;
  max-width: 100%;
}

.GalleryLinks .landing-link:hover {
  box-shadow: 0 40px 40px rgba(15, 24, 37, .1);
}

.GalleryLinks .landing-link .landing-link-image {
  border: 1px solid rgba(15, 24, 37, .1);
  margin-bottom: 24px;
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;
}

.GalleryLinks .landing-link h3 {
  font-size: 22px;
  font-weight: 500;
  display: inline-block;
  line-height: 32px;
}

.GalleryLinks .landing-link .portfolio-link-arrow {
  margin-bottom: 8px;
  margin-left: 10px;
  width: 16px;
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;
}