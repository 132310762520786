.B5_S3 {
  font-family: "Quicksand", sans-serif;
  padding-top: 200px;
  padding-bottom: 100px;
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url('./images/bg.jpg') center center;

  text-align: center;
  color: #ffffff;
  background-size: cover;
  background-position: center;
  position: relative;
}

.B5_S3 .container {
  position: relative;
  z-index: 2;
}

.B5_S3::after {
  content: '';
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .3);
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 1;
}

.B5_S3 h2.section-title {
  font-family: "Quicksand", sans-serif;
  text-align: center;
  font-size: 40px;
  line-height: 1.2;
  margin-bottom: 15px;
}

.B5_S3 .section-subtitle {
  text-align: center;
  font-size: 20px;
  padding: 0px 100px 0px 100px;
}

@media(max-width:768px) {
  .B5_S3 .section-subtitle {
    padding: 0px;
  }

}