.CtaText {
  font-family: "Quicksand", sans-serif;
  padding-top: 90px;
  padding-bottom: 60px;
  background-color: #616775;
  text-align: center;
  color: #ffffff;
}

.CtaText h2.section-title {
  font-family: "Quicksand", sans-serif;
  text-align: center;
  font-size: 40px;
  line-height: 1.2;
  margin-bottom: 15px;
}

.CtaText .section-subtitle {
  text-align: center;
  font-size: 20px;
}

.CtaText a.section-btn {
  width: fit-content;
  padding: 10px 40px;
  color: #ffffff;
  text-align: center;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #ffffff;
  border-radius: 3px;
  margin: 0px auto;
  font-size: 20px;
}

.CtaText a.section-btn:hover {
  color: #616775;
  background-color: #ffffff ;
}

@media(max-width:768px) {

  .CtaText a.section-btn {
    margin-top: 20px;
  }

}