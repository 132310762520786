.FaqList {
  padding-top: 100px;
  padding-bottom: 100px;
  font-family: "Quicksand", sans-serif;
  ;
  padding-left: 40px;
  padding-right: 40px;
}

.FaqList h2.title {
  font-size: 50px;
  line-height: 62px;
  font-weight: 400;
  margin-bottom: 24px;
}

.FaqList .accordion-button {
  font-size: 20px;
  font-weight: 600;
  font-family: "Quicksand", sans-serif;
  line-height: 30px;
}

.FaqList .accordion-button:not(.collapsed) {
  box-shadow: none;
  background-color: transparent;
  color: black;
}

.FaqList .accordion-button::after {
  width: 2rem;
  height: 2rem;
  background-color: #ffe01b;
  background-image: url(./images/plus.svg);
  background-size: cover;
  border-radius: 50%;
}

.FaqList .accordion-button:not(.collapsed)::after {
  background-image: url(./images/minus.svg);
  transform: rotate(0);
}

.FaqList .accordion-item {
  border: 0px;
  border-top: .0625rem solid rgba(36, 28, 21, .3) !important;
  border-radius: 0px !important;
}

.FaqList .accordion-header {
  padding-top: 8px;
  padding-bottom: 8px;
}

.FaqList .accordion-body {
  font-size: 16px;
}

.FaqList .accordion-body ul {
  padding: 0px;
  padding-left: 15px;
}

.FaqList .accordion-button:focus {
  box-shadow: none;
  border: 0px;
}

@media (max-width:768px) {
  .FaqList {
    padding-left: 15px;
    padding-right: 15px;
  }

  .FaqList h2.title {
    font-size: 45px;
    line-height: 55px;
  }

  .FaqList .accordion-button {
    padding: 10px 0px;
    font-size: 16px;
    line-height: 24px;
  }

  .FaqList .accordion-body {
    padding: 5px 0px 10px;
  }
}