/* Hero section1 css */
.custom-container {
  width: 100%;
}

.HeroSlider {
  height: 100vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  padding-left: 40px;
  padding-right: 40px;
  font-family: 'Quicksand', sans-serif;
}

.background {
  transition: all 0.5s ease-in-out;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: -1;
}

.wrapper {
  display: flex;
  justify-content: center;
}

.HeroSlider .HeroSlider-content {
  max-width: 1000px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.HeroSlider .HeroSlider-content h1 {
  font-size: 80px;
  line-height: 80px;
  font-weight: 400;
  color: #ffffff;
  padding-top: 5rem;
  margin-bottom: 24px;
}

.HeroSlider .HeroSlider-content p {
  font-size: 20px;
  font-weight: 400;
  line-height: 34px;
  color: #ffffff;
  margin-bottom: 30px;
}

a.arrow-scroll-down {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  border-radius: 100%;
  border: 1px solid #ffffff;
  position: absolute;
  left: 0px;
  right: 0px;
  margin: 0px auto;
  bottom: 40px;
  color: #ffffff;
  text-decoration: none;
}

a.arrow-scroll-down:hover {
  background-color: #ffffff;
  color: black;
}

.HeroSlider-buttons {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.HeroSlider-buttons a.btn-propostal {
  color: #ffffff;
  background-color: #5e6777;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  padding: 16px 12px;
  display: flex;
  transition: background-color .2s;
  font-size: 16px;
  font-weight: 600;
  line-height: 32px;
  width: 190px;
  height: 56px;
  margin-right: 16px;
  text-decoration: none;
  transition: background-color .2s;
}

.HeroSlider-buttons a.btn-propostal:hover {
  background-color: #363b44;
}

.HeroSlider-buttons a.btn-propostal img {
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;
  width: 19px;
  margin-right: 16px;
}

.HeroSlider-buttons a.btn-play-video {
  color: #0f1825;
  background-color: #ffffff;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  padding: 16px 12px;
  display: flex;
  transition: background-color .2s;
  font-size: 16px;
  font-weight: 600;
  line-height: 32px;
  width: 146px;
  height: 56px;
}


@media (min-width:1320px) {
  .custom-container {
    width: 1280px !important;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width:1400px) {
  .custom-container {
    width: 1320px !important;
    margin-left: auto;
    margin-right: auto;
  }
}

/* media queriy below 1200px css */
@media (max-width:1200px) {
  .HeroSlider h1 {
    font-size: 60px;
    line-height: 78px;
  }
}

/* media queriy below 992px css */
@media(max-width:992px) {
  .HeroSlider {
    min-height: 100vh;
    aspect-ratio: 5/4;
    height: auto;
    background-position: center right;
  }
}

/* media queriy below 768px css */
@media (max-width:768px) {
  .HeroSlider {
    aspect-ratio: 2.6/4;
    background-position: center;
    padding-left: 15px;
    padding-right: 15px;
  }

  .HeroSlider .HeroSlider-content h1 {
    font-size: 45px;
    line-height: 55px;
    padding-top: 4rem;
  }

  .HeroSlider .HeroSlider-content p {
    padding-right: 0px;
    line-height: 1.5;
  }

  .HeroSlider-buttons {
    flex-direction: column;
  }

  .HeroSlider-buttons a.btn-propostal {
    width: 100%;
    margin-right: 0px;
    margin-bottom: 10px;
  }

  a.arrow-scroll-down {
    position: inherit;
    margin-top: 30%;
  }
}

@media (max-width:350px) {
  .HeroSlider {
    aspect-ratio: 2/4;
  }

  a.arrow-scroll-down {
    position: inherit;
  }
}