.CustomerLogos {
  padding-top: 12px;
  padding-bottom: 12px;
  background-color: #ffffff;
}

.CustomerLogos .client-cover {
  display: flex;
  align-items: center;
  justify-content: center;
}

.CustomerLogos .client-cover img {
  max-width: 45%;
  transition: all 0.4s ease-in-out;
  display: inline-block;
  padding: 15px 0;
  filter: grayscale(100);
}

.CustomerLogos .client-cover img:hover {
  filter: none;
  transform: scale(1.1);
}