.FooterSmallStatic {
  background-color: #f9f9f6;
  color: #333b22;
  padding-top: 72px;
  padding-bottom: 40px;
  padding-left: 5vw;
  padding-right: 5vw;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
}

.FooterSmallStatic a.footer-logo {
  display: block;
  height: 56px;
  margin-bottom: 40px;
}

.FooterSmallStatic a.footer-logo img {
  height: 100%;
}

.FooterSmallStatic .footer-content {
  padding-top: 56px;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 17px;
}

.FooterSmallStatic .footer-content p {
  color: #333b22;
  margin-bottom: 24px;
}

.FooterSmallStatic a {
  color: #4F7A8B;
}

.FooterSmallStatic .footer-content a {
  background: no-repeat bottom;
  background-image: linear-gradient(#333b22, #333b22);
  background-size: 0 1px;
  border: none;
  text-decoration: none;
  transition-delay: 0s;
  transition-duration: .3s;
  transition-property: all;
  padding-bottom: 2px;
}

.FooterSmallStatic .footer-content a.footer-mail {
  color: #333b22;
}

.FooterSmallStatic .footer-content a:hover {
  background-size: 100% 1px;
}

.FooterSmallStatic .footer-content-right {
  display: flex;
  justify-content: space-between;
}

.FooterSmallStatic .footer-content-right ul {
  padding-left: 0px;
}

.FooterSmallStatic .footer-content-right ul li {
  list-style-type: none;
  padding-bottom: 16px;
}

.FooterSmallStatic .footer-content-right ul li a {
  text-decoration: none;
  background-image: linear-gradient(#ff6482, #ff6482) !important;
  font-size: 30px;
  line-height: 40px;
  background-size: 0 2px;
  color: #ffffff;
  font-weight: 400;
  font-family: thicccboi-regular;
  background-size: 0 2px;
}

.FooterSmallStatic .footer-content-right ul li a:hover {
  background-size: 100% 2px;
}

.FooterSmallStatic .copyright {
  padding-top: 20px;
  font-size: 12px;
}

.FooterSmallStatic .copyright a {
  background: no-repeat bottom;
  background-image: linear-gradient(#333b22, #333b22);
  background-size: 0 1px;
  border: none;
  text-decoration: none;
  transition-delay: 0s;
  transition-duration: .3s;
  transition-property: all;
  padding-bottom: 2px;
  color: #ffffff;
}

.FooterSmallStatic .copyright a:hover {
  background-size: 100% 1px;
}