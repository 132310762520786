.BoxCircle {
  font-family: "Quicksand", sans-serif;
  padding-top: 60px;
  padding-bottom: 60px;
  text-align: center;
  background: #f9f9f6;
}

.BoxCircle h2.section-title {
  font-family: "Quicksand", sans-serif;
  text-align: center;
  font-size: 40px;
  line-height: 1.2;
  margin-bottom: 15px;
}

.BoxCircle .section-subtitle {
  text-align: center;
  font-size: 20px;
  padding: 0px 100px 0 100px;
}

.BoxCircle .services-item-cover {
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
  font-family: "Quicksand", sans-serif;
  color: #616775;
}

.BoxCircle .services-item-link {
  text-decoration: none;
  color: #000000;
}

.BoxCircle .services-item-link:hover {
  text-decoration: underline;
}

.BoxCircle .services-item:hover {
  background-color: #363b44;
}

.BoxCircle .services-item {
  width: 140px;
  height: 140px;
  flex: 0 0 140px;
  border-radius: 100%;
  background-color: #616775;
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 10px;
}

.BoxCircle .services-item img {
  width: 90px;
}

.BoxCircle a.section-btn {
  width: fit-content;
  padding: 10px 40px;
  color: #000000;
  text-align: center;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #000000;
  border-radius: 3px;
  margin: 0px auto;
  font-size: 20px
}

.BoxCircle a.section-btn:hover {
  color: #ffffff;
  background-color: #363b44;
  border: 2px solid #363b44;
}

@media(max-width:768px) {
  .BoxCircle .services-item-cover {
    padding-bottom: 0px;
  }

  .BoxCircle h2.section-title {
    font-size: 30px;
  }

  .BoxCircle a.section-btn {
    margin-top: 20px;
  }

  .BoxCircle .section-subtitle {
    padding: 0px;
  }

}