.CardImageLeft {
  padding-top: 95px;
  padding-bottom: 95px;
  background-color: #ffffff;
  text-align: left;
  word-wrap: break-word;
  hyphens: auto;
  font-family: "Quicksand", sans-serif;

}

.CardImageLeft .mod-leftp {
  padding-left: 20px;
}

.CardImageLeft h2 {
  font-size: 40px;
  line-height: 48px;
  font-weight: 500;
  margin-bottom: 30px;
}

.CardImageLeft p {
  font-size: 20px;
}

.CardImageLeft .buttons a.btn-propostal:hover{
  background-color: #363b44;
} 
.CardImageLeft .buttons a.btn-propostal img {
    max-width: 100%;
    vertical-align: middle;
    display: inline-block;
    width: 19px;
    margin-right: 16px;
}
.CardImageLeft .buttons a.btn-play-video{
    color: #0f1825;
    background-color:#ffffff;
    border-radius: 4px;
    justify-content: center;
    align-items: center;
    padding:16px 12px;
    display: flex;
    transition: background-color .2s;
    font-size:16px;
    font-weight: 600;
    line-height: 32px;
    width:146px;
    height:56px;
}


.CardImageLeft .mod-right {
  transition: opacity .4s ease .1s;
  background-size: cover;
  background-position: center center;
}

@media (max-width:768px) {
  .CardImageLeft .mod-left {
    padding-left: 10px;
  }

  .CardImageLeft .buttons {
    flex-direction: column;
  }

  .CardImageLeft .buttons a.btn-propostal {
    width: 100%;
    margin-right: 0px;
    margin-bottom: 10px;
  }

  .CardImageLeft .image {
    width: 100%;
    height: 400px;
    margin-bottom: 40px;
    border-radius: 4px;
  }
}