#NavbarStandard {
  transition: all 0.5s;
  z-index: 997;
  position: fixed;
  width: 100%;
}

.sticky-transparent {
  background-color: #363b445c !important;
  /* padding: 0px; */
}

.NavbarStandard {
  position: fixed;
  width: 100%;
  background-color: #363b44;
}

.NavbarStandard nav.navbar .container {
  position: relative;
}

.NavbarStandard .navbar-brand img {
  max-width: 110px;
  max-height: 50px;
}

.NavbarStandard a.nav-link {
  padding: 3px 9px;
  margin-top: 0;
  transition: border-color .2s;
  color: #ffffff;
  margin-left: 4px;
  margin-right: 4px;
  line-height: 1.5;
  border-radius: 4px;
  transition: all .3s;
  font-size: 16px;
}

.NavbarStandard a.nav-link.active {
  color: #182F42 !important;
  /*  background-color:#ffffff;*/
}

.NavbarStandard a.nav-link:hover {
  color: #003057 !important;
  background-color: #ffffff;
  border-radius: 4px;
}

.NavbarStandard a.nav-link img.dropdown-arrow {
  margin-left: 6px;
}

.NavbarStandard a.nav-link.btn-nav {
  color: #ffffff;
  background-color: #5e6777;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  padding: 12px 20px;
  display: flex;
  transition: background-color .2s;
  font-family: 'Quicksand', sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 32px;
  margin-left: 28px;
  width: 200.52px;
  height: 40px;
  border-top: 0px;
}

.NavbarStandard a.nav-link.btn-nav img {
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;
  width: 19px;
  margin-right: 16px;
}

.NavbarStandard a.nav-link.icon {
  max-width: 100%;
  font-size: 20px;
}

/* .NavbarStandard a.nav-link.icon:hover {
  color: #4c525c !important;
  border-radius: 4px;
} */

.NavbarStandard a.nav-link.btn-nav:hover {
  background-color: #4c525c;
  color: #ffffff !important;
}

.NavbarStandard .navbar-toggler {
  border: 0px;
}

.NavbarStandard .navbar-toggler.collapsed .navbar-toggler-icon {
  background-image: url(./images/bars-solid.svg);
  width: 22px;
}

.NavbarStandard .navbar-toggler .navbar-toggler-icon {
  background-image: url(./images/xmark-solid.svg);
  width: 20px;
}

.NavbarStandard .navbar-toggler:focus {
  box-shadow: none;
}

@media (min-width: 768px) {
  .NavbarStandard .navbar-expand-lg .navbar-nav .nav-link {
    padding-left: 12px;
    padding-right: 12px;
  }
}

@media(max-width:768px) {
  .NavbarStandard nav.navbar .container {
    text-align: center;
  }

  .NavbarStandard nav.nav-link .container .navbar-collapse {
    padding-top: 56px;
  }

  .NavbarStandard nav.navbar li.nav-item {
    width: 100%;
  }

  .NavbarStandard a.nav-link {
    border-top: 0px;
    transition: none;
    font-size: 18px;
  }

  .NavbarStandard a.nav-link.btn-nav {
    width: 100%;
    margin-left: 0px;
    margin-top: 20px;
  }
}