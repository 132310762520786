.BoxText {
    padding-top: 95px;
    padding-bottom: 95px;
    background-color: #f3f7fa;
}

.BoxText h2 {
    font-size: 48px;
    line-height: 48px;
    font-family: "Poppins";
    font-weight: 600;
    margin-bottom: 30px;
}

.BoxText p {
    font-size: 16px;
    line-height: 1.7;
    margin-bottom: 30px;
}

.BoxText .icon-box {
    box-shadow: 0px 0 25px 0 rgba(0, 0, 0, 0.1);
    padding: 50px 30px;
    transition: all ease-in-out 0.4s;
    background: #fff;
}

.BoxText .icon-box:hover {
    transform: translateY(-10px);
}

.BoxText .icon-box .icon {
    margin-bottom: 10px;
}

.BoxText .icon-box .icon i {
    color: #47b2e4;
    font-size: 36px;
    transition: 0.3s;
}

.BoxText .icon-box h4 {
    font-weight: 500;
    margin-bottom: 15px;
    font-size: 24px;
}

.BoxText .icon-box h4 a {
    color: #37517e;
    transition: ease-in-out 0.3s;
    text-decoration: none;
    font-weight: 700;
    font-family: "Poppins", sans-serif;
}

.BoxText .icon-box p {
    line-height: 24px;
    font-size: 14px;
    margin-bottom: 0;
}

.BoxText .icon-box-cover {
    display: flex;
    align-items: stretch;
    margin-top: 24px;
}

.BoxText .icon-box:hover h4 a {
    color: #47b2e4;
}