.GalleryPictures {
  font-family: "Quicksand", sans-serif;
  padding-top: 0px;
  padding-bottom: 60px;
  background: #f9f9f6;
  text-align: center;
}

.GalleryPictures .mod-item-cover {
  padding: 5px;
}

.GalleryPictures h2.section-title {
  font-family: "Quicksand", sans-serif;
  text-align: center;
  font-size: 40px;
  line-height: 1.2;
  margin-bottom: 15px;
}

.GalleryPictures .section-subtitle {
  text-align: center;
  font-size: 20px;
  margin-bottom: 40px;
}

.GalleryPictures .mod-item {
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 100px 60px;
  height: 100%;
}

.GalleryPictures .mod-item:hover {
  background-color: #e9e9e9;
}

.GalleryPictures .mod-item img {
  width: 100%;
  display: block;
}

@media(max-width:768px) {

  .GalleryPictures .mod-item {
    padding: 80px 40px;
  }

  .GalleryPictures h2.section-title {
    font-size: 30px;
  }

  .GalleryPictures .section-subtitle {
    margin-bottom: 30px;
  }

}