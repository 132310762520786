.CustomerFeedback{
  padding-top: 95px;
  padding-bottom: 95px;
  background-color: #f6f7fa;
}
.CustomerFeedback .review-item-cover{
  margin-bottom: 40px;
}
.CustomerFeedback .review-item{

}
.CustomerFeedback .review-item img{
  margin-bottom: 10px;
}
.CustomerFeedback .review-item p{
  font-size: 20px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  line-height: 36px;
  color: #0f1825;
  margin-bottom: 20px;
}
.CustomerFeedback .user-details-cover img{
  width: 50px;
    border-radius: 50px;
    margin-top: -26px;
    margin-right: 20px;
    max-width: 100%;
    vertical-align: middle;
    display: inline-block;
}
.CustomerFeedback .user-details-cover .user-text{
    font-size: 14px;
    display: inline-block;
    color: rgba(0,0,0,.6);
    font-weight: 500;
    line-height:23px;
    font-family:"Poppins";
}