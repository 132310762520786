.B1_S6 {
  background: linear-gradient(rgba(40, 58, 90, 0.9), rgba(40, 58, 90, 0.9)), url(./cta-bg.jpg) fixed center center;
  background-size: cover;
  padding: 120px 0;
}

.B1_S6 .mod-left {
  text-align: left;
}

.B1_S6 .mod-right {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.B1_S6 h3 {
  color: #fff;
  font-size: 28px;
  font-weight: 700;
}

.B1_S6 p {
  color: #ffffff;
  line-height: 24px;
}

.B1_S6 a.cta-btn {
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 12px 30px;
  border-radius: 50px;
  transition: 0.5s;
  margin: 10px;
  border: 2px solid #fff;
  color: #fff;
  text-decoration: none;
  line-height: 1.5;
}

.B1_S6 a.cta-btn:hover {
  background: #47b2e4;
  border: 2px solid #47b2e4;
}