
.FormContactLargeDynamic .form-section {
    padding-bottom: 120px;
    padding-top: 100px;
    background-color: #616775;
}

.FormContactLargeDynamic .form-section .container h1 {
    margin-top: 0;
    margin-bottom: 24px;
    font-family: "QuickSand", sans-serif;
    font-size: 40px;
    font-weight: 500;
    line-height: 80px;
    color: #ffffff;
}

.FormContactLargeDynamic .form-section .container p {
    font-family: "QuickSand", sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 34px;
    margin-bottom: 20px;
    padding-top: 15px;
    color: rgba(255, 255, 255, .8);
}

.FormContactLargeDynamic .form-section .container {
    max-width: 712px;
    padding-left: 40px;
    padding-right: 40px;
}

.FormContactLargeDynamic .form-section .container img.form-section-logo {
    width: 116px;
    padding-bottom: 20px;
}

.FormContactLargeDynamic .form-section .form-cover {
    background-color: #fff;
    margin-bottom: 0;
    padding: 60px;
    position: relative;
    margin-top: 40px;
    color: #0f1825;
}

.FormContactLargeDynamic .form-section .form-cover .form label {
    font-size: 16px;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    line-height: 26px;
}

.FormContactLargeDynamic .form-section .form-cover .form .form-control {
    padding: 8px 12px;
    font-size: 14px;
    line-height: 1.42857;
    display: block;
    color: #333;
    vertical-align: middle;
    width: 100%;
    height: 60px;
    background-color: transparent;
    border: 1px #000;
    border-bottom: 1px solid rgba(0, 0, 0, .1);
    margin-bottom: 18px;
    padding-top: 16px;
    padding-bottom: 16px;
    padding-left: 0;
    transition: opacity .2s;
    outline: 0px;
    box-shadow: none;
    border-radius: 0px;
}

.FormContactLargeDynamic .form-section .form-cover .form .form-control:hover {
    border-bottom-color: #000;
}

.FormContactLargeDynamic .form-section .form-cover .form .form-control:focus {
    outline: 0px;
    box-shadow: none;
    border-bottom-color: #000;
}

.FormContactLargeDynamic .form-section .form-cover .form .form-control::-webkit-input-placeholder {
    /* Edge */
    color: #999;
    opacity: .6;
    font-size: 14px;
}

.FormContactLargeDynamic .form-section .form-cover .form .form-control:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #999;
    opacity: .6;
    font-size: 14px;
}

.FormContactLargeDynamic .form-section .form-cover .form .form-control::placeholder {
    color: #999;
    opacity: .6;
    font-size: 14px;
}

.FormContactLargeDynamic .form-section .form-cover .form label.last-label {
    padding-top: 24px;
    margin-bottom: 10px;
}

.FormContactLargeDynamic .form-section .form-cover .form p {
    margin-bottom: 18px;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    font-family: "Poppins", sans-serif;
    color: rgba(15, 24, 37, .7);
    padding-top: 0px;
}

.FormContactLargeDynamic .form-section .form-cover .form textarea.form-control {
    height: auto;
}

.FormContactLargeDynamic .form-section .form-cover .form .btn-submit {
    border: 0px;
    padding: 9px 15px;
    width: 100%;
    border-radius: 4px;
    padding-top: 16px;
    padding-bottom: 16px;
    line-height: 24px;
    display: block;
    margin-top: 40px;
    -webkit-appearance: button;
    font-family: "Poppins", sans-serif;
    font-size: 18px;
    font-weight: 600;
    background-color: #5e6777;
    color: #ffffff;
    transition: background-color .2s;
}

.FormContactLargeDynamic .form-section .form-cover .form .btn-submit:hover {
    background-color: #383b43;
}

.FormContactLargeDynamic .kontakt-info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: #fff;
    padding-top: 30px;
    font-size: 20px;
    font-family: "QuickSand", sans-serif;
}

.FormContactLargeDynamic .kontakt-info h2 {
    font-weight: 600;
}

@media(max-width:768px) {
    .FormContactLargeDynamic .form-section .container h1 {
        font-size: 45px;
        line-height: 50px;
    }

    .FormContactLargeDynamic .form-section .container {
        padding-left: 12px;
        padding-right: 12px;
    }

    .FormContactLargeDynamic .form-section .form-cover {
        margin-top: 32px;
        padding: 40px;
    }

    .FormContactLargeDynamic .kontakt-info {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        color: #fff;
        padding-top: 30px;
        font-size: 20px;
        font-family: "QuickSand", sans-serif;
    }
    .FormContactLargeDynamic .kontakt-person {
        padding-bottom: 40px;
    }
}