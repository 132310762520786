.GalleryDescriptions {
  padding-top: 0px;
  padding-bottom: 95px;
  background-color: #f6f7fa;
}

.GalleryDescriptions h2 {
  font-size: 48px;
  line-height: 48px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  margin-bottom: 30px;
}

.GalleryDescriptions p {
  font-size: 16px;
  line-height: 1.7;
  margin-bottom: 30px;
}

.GalleryDescriptions .team-items {
  padding-top: 30px;
}

.GalleryDescriptions .team-item-cover {
  margin-bottom: 16px;
}

.GalleryDescriptions .team-item {
  background-color: #fff;
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 0;
  padding-bottom: 30px;
  height: 100%;
}

.GalleryDescriptions .team-item-image img {
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;
}

.GalleryDescriptions .team-item-name {
  margin-top: 35px;
  margin-left: 35px;
  margin-right: 35px;
  padding-left: 0;
  padding-right: 0;
  color: #0f1825;
  margin-top: 30px;
  margin-bottom: 10px
}

.GalleryDescriptions .team-item-name h2 {
  font-size: 30px;
  font-weight: 500;
  line-height: 44px;
  margin-bottom: 16px;
}

.GalleryDescriptions .team-item-designation {
  margin-left: 35px;
  margin-right: 35px;
  padding-left: 0;
  padding-right: 0;
}

.GalleryDescriptions .team-item-designation p {
  color: #0f1825;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 0px;
}